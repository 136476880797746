// import {Injectable} from '@angular/core';
// import {
//     CanActivate,
//     CanActivateChild,
//     ActivatedRouteSnapshot,
//     RouterStateSnapshot,
//     UrlTree,
//     Router
// } from '@angular/router';
// import {Observable} from 'rxjs';
// import {AppService} from '@services/app.service';

// @Injectable({
//     providedIn: 'root'
// })
// export class AuthGuard implements CanActivate, CanActivateChild {
//     constructor(
//         private router: Router,
//         private appService: AppService
//     ) {}

//     canActivate(
//         next: ActivatedRouteSnapshot,
//         state: RouterStateSnapshot
//     ):
//         | Observable<boolean | UrlTree>
//         | Promise<boolean | UrlTree>
//         | boolean
//         | UrlTree {
//         return this.getProfile();
//     }

//     canActivateChild(
//         next: ActivatedRouteSnapshot,
//         state: RouterStateSnapshot
//     ):
//         | Observable<boolean | UrlTree>
//         | Promise<boolean | UrlTree>
//         | boolean
//         | UrlTree {
//         return this.canActivate(next, state);
//     }

//     async getProfile() {
//         if (this.appService.user) {
//             return true;
//         }

//         try {
//             await this.appService.getProfile();
//             return true;
//         } catch (error) {
//             return false;
//         }
//     }
// }


import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateFn, CanActivateChild } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Observable } from 'rxjs';
import { IsAuthService } from '../service/is-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild {
  constructor(private isAut:IsAuthService,private auth:AuthService,private router:Router) {}
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(!this.isAut.isAuthenticated()){
            this.router.navigate(['/login']);
            //this.router.navigate(['pages/verifyloginotp'])
            return false;
          }
            return this.isAut.isAuthenticated();
    }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean{
      if(!this.isAut.isAuthenticated()){
        this.router.navigate(['/login']);
        //this.router.navigate(['pages/verifyloginotp'])
        return false;
      }
        return this.isAut.isAuthenticated();
      
  }
  
}