import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, Renderer2, TemplateRef} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {PageEvent} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {map, startWith} from 'rxjs';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
    selector: 'app-deviceeventlogs',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        MatPaginatorModule
    ],
    templateUrl: './deviceeventlogs.component.html',
    styleUrl: './deviceeventlogs.component.scss'
})
export class DeviceeventlogsComponent implements OnInit {
    filteredSuccessFail$: any;
    successFailFilterCtrl: FormControl = new FormControl();

    prop: any;
    data: any;
    total: any;

    deviceProp: any;
    deviceData: any;
    devicetotal: any;

    isLoading = true; // Add this line to show the loader at the beginning

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient,
        private renderer: Renderer2
    ) {
        this.pageEvent = new PageEvent();

        this.filteredSuccessFail$ =
            this.successFailFilterCtrl.valueChanges.pipe(
                startWith(''),
                map((filterString) => this.filter(filterString))
            );
    }

    length: any; // Replace with your total data count from API
    page = 1;
    pageSize = 10; // Replace with your default page size
    pageSizeOptions: number[] = [2, 5, 10, 25, 50, 100]; // Or your page size options
    pageEvent: PageEvent;

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput
            .split(',')
            .map((str) => +str);
    }

    searchDeviceName: string;

    ngOnInit(): void {
        this.searchDeviceName = this.isAuth.getDeviceId();
        //console.log('device name', this.searchDeviceName);
        this.showTerminalLog();
    }

    displayedLogData: any;

    filterFromTable(input: any) {
        //console.log(input);

        if (!input) {
            // If there's no input, reset to the original dataset
            this.displayedLogData = [...this.data];
        } else {
            const res = this.data.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.displayedLogData = res;
        }
    }

    sortData(sortField: string, sortDirection: string) {
        this.displayedLogData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    capitalizeAndReplace(str: string): string {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    }

    showTerminalLog() {
        let deviceName = this.isAuth.getDeviceId();
        //console.log(deviceName);

        try {
            let sendData = {
                device_name: deviceName,
                page: this.page,
                per_page: this.pageSize
            };

            //console.log('send data ng on init', sendData);

            this.service
                .postDeviceEventLogs(sendData)
                .subscribe((data: any) => {
                    //console.log("Request Log API Response",data);
                    if (data['status'] == true) {
                        //console.log("here");

                        this.prop = [];
                        for (var property in data['data'][0]) {
                            if (data['data'][0].hasOwnProperty(property)) {
                                //console.log("PROPERTY",property);
                                if (
                                    property.toLowerCase() != 'total' &&
                                    property.toLowerCase() != 'user_id'
                                ) {
                                    this.prop.push(property);
                                }
                                //console.log(this.prop);
                            }
                        }

                        this.rearrangeArray();
                        this.isLoading = false;
                        this.data = data['data'];
                        this.displayedLogData = [...this.data];
                        this.length = data['total_entries'];
                        this.total = data['total_entries'];
                    } else {
                        this.isLoading = false;
                        this.data = null;
                        this.toast.error(data['data']);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    userName = localStorage.getItem('UserName');

    pageIndex: number = 0;

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        //console.log(event.pageSize); // This will give you the current items per page
        this.loadData(event.pageIndex, event.pageSize);
    }

    loadData(pageIndex: number, pageSize: number) {
        this.isLoading = true; // Add this line to show the loader at the beginning
        // Call your API here with pageIndex and pageSize as parameters
        if (pageIndex == 0) {
            pageIndex = 1;
        } else {
            pageIndex++;
        }

        let deviceName = this.isAuth.getDeviceId();

        if (this.searchOn === true) {
            //console.log('search data');

            let deviceName = this.searchDeviceName;

            let sendData = {
                device_name: deviceName,
                global_user_key: this.searchFormData.value.globalUserKey,
                event: this.selectedEventType,
                wifi_SSID: this.searchFormData.value.wiFiSSID,
                local_ip_address: this.searchFormData.value.localIp,
                global_ip_address: this.searchFormData.value.globalIp,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                page: pageIndex,
                per_page: pageSize
            };

            //console.log("Search send data",sendData);

            this.service
                .postDeviceSearchEventLogs(sendData)
                .subscribe((data: any) => {
                    //console.log("search Response",data);
                    if (data['status'] == true) {
                        //console.log("here");

                        this.prop = [];
                        for (var property in data['data'][0]) {
                            if (data['data'][0].hasOwnProperty(property)) {
                                //console.log("PROPERTY",property);
                                if (
                                    property.toLowerCase() != 'total' &&
                                    property.toLowerCase() != 'user_id'
                                ) {
                                    this.prop.push(property);
                                }
                                //console.log(this.prop);
                            }
                        }

                        this.rearrangeArray();
                        this.isLoading = false;
                        this.data = data['data'];
                        this.displayedLogData = [...this.data];
                        this.length = data['total_entries'];
                        this.total = data['total_entries'];
                    } else {
                        this.isLoading = false;
                        this.data = null;
                        this.toast.error('No Data Found');
                    }
                });
        } else {
            let sendData = {
                device_name: deviceName,
                page: pageIndex,
                per_page: pageSize
            };

            this.service
                .postDeviceEventLogs(sendData)
                .subscribe((data: any) => {
                    //console.log("Request Log API Response",data);
                    if (data['status'] == true) {
                        //console.log("here");

                        this.prop = [];
                        for (var property in data['data'][0]) {
                            if (data['data'][0].hasOwnProperty(property)) {
                                //console.log("PROPERTY",property);
                                if (
                                    property.toLowerCase() != 'total' &&
                                    property.toLowerCase() != 'user_id'
                                ) {
                                    this.prop.push(property);
                                }
                                //console.log(this.prop);
                            }
                        }

                        this.rearrangeArray();
                        this.isLoading = false;
                        this.data = data['data'];
                        this.displayedLogData = [...this.data];
                        this.length = data['total_entries'];
                        this.total = data['total_entries'];
                    } else {
                        this.isLoading = false;
                        this.data = null;
                        this.toast.error(data['data']);
                    }
                });
        }
    }

    rearrangeArray() {
        const dateIdx = this.prop.indexOf('id');
        if (dateIdx > -1) {
            const userNameElement = this.prop.splice(dateIdx, 1)[0];
            this.prop.unshift(userNameElement);
        }
    }

    displaySearchForm = false;

    toggleSearchForm() {
        this.displaySearchForm = !this.displaySearchForm;
    }

    //fromDate = new Date().toISOString().split("T")[0];  // New variable
    //toDate = new Date().toISOString().split("T")[0];  // New variable

    searchFormData = new FormGroup({
        fromDate: new FormControl('', [Validators.required]),
        toDate: new FormControl('', [Validators.required]),
        deviceId: new FormControl('', [Validators.required]),
        globalUserKey: new FormControl('', [Validators.required]),
        wiFiSSID: new FormControl('', [Validators.required]),
        localIp: new FormControl('', [Validators.required]),
        globalIp: new FormControl('', [Validators.required])
    });

    get deviceId(): FormControl {
        return this.searchFormData.get('deviceId') as FormControl;
    }

    get globalUserKey(): FormControl {
        return this.searchFormData.get('globalUserKey') as FormControl;
    }

    get wiFiSSID(): FormControl {
        return this.searchFormData.get('wiFiSSID') as FormControl;
    }

    get localIp(): FormControl {
        return this.searchFormData.get('localIp') as FormControl;
    }

    get globalIp(): FormControl {
        return this.searchFormData.get('globalIp') as FormControl;
    }

    showDownloadButtons = false;

    searchOn = false;

    applyDateCheckedFromDate: any;
    applyDateCheckedToDate: any;

    onSearch() {
        this.isLoading = true;
        this.searchOn = true;
        try {
            this.showDownloadButtons = true;

            const dateNew = (<HTMLInputElement>(
                document.getElementById('applyDate')
            )).checked;
            //console.log(dateNew);

            if (dateNew === true) {
                this.applyDateCheckedFromDate =
                    this.searchFormData.value.fromDate;
                this.applyDateCheckedToDate = this.searchFormData.value.toDate;
            } else {
                this.applyDateCheckedFromDate = '';
                this.applyDateCheckedToDate = '';
            }

            //console.log("check",this.applyDateCheckedFromDate,this.applyDateCheckedToDate);
            let deviceName = this.isAuth.getDeviceId();

            let sendData = {
                device_name: deviceName,
                global_user_key: this.searchFormData.value.globalUserKey,
                event: this.selectedEventType,
                wifi_SSID: this.searchFormData.value.wiFiSSID,
                local_ip_address: this.searchFormData.value.localIp,
                global_ip_address: this.searchFormData.value.globalIp,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                page: this.page,
                per_page: this.pageSize
            };

            //console.log("Search send data",sendData);

            this.service
                .postDeviceSearchEventLogs(sendData)
                .subscribe((data: any) => {
                    //console.log("search Response",data);
                    if (data['status'] == true) {
                        //console.log("here");

                        this.prop = [];
                        for (var property in data['data'][0]) {
                            if (data['data'][0].hasOwnProperty(property)) {
                                //console.log("PROPERTY",property);
                                if (
                                    property.toLowerCase() != 'total' &&
                                    property.toLowerCase() != 'user_id'
                                ) {
                                    this.prop.push(property);
                                }
                                //console.log(this.prop);
                            }
                        }

                        this.rearrangeArray();
                        this.isLoading = false;
                        this.data = data['data'];
                        this.displayedLogData = [...this.data];
                        this.length = data['total_entries'];
                        this.total = data['total_entries'];
                    } else {
                        this.isLoading = false;
                        this.data = null;
                        this.toast.error('No Data Found');
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    changeColor(event: any) {
        this.renderer.setStyle(event.target, 'background-color', 'green');
        this.renderer.setStyle(event.target, 'border-color', 'green');
    }

    status = {
        data: [
            {id: 1, status: 'success'},
            {id: 2, status: 'fail'}
        ]
    };

    selectedSuccessFailType: any;
    showSuccessFailModel: any = this.status['data'];
    changeSuccessFailType(value: any) {
        //console.log(value);
    }

    selectedModelName: any;

    changeModelName(value: any) {
        //console.log(value);
        this.selectedModelName = value;
    }
    eventType = {
        data: [
            {id: 1, eventType: 'POWER ON'},
            {id: 2, eventType: 'POWER OFF'},
            {id: 3, eventType: 'HEART BEAT'}
        ]
    };
    selectedEventType: any;
    showEventTypeModel: any = this.eventType['data'];

    changeEventType(value: any) {
        //console.log(value);
        this.selectedEventType = value;
    }

    filter(filterString: string) {
        return this.showSuccessFailModel
            ? this.showSuccessFailModel.filter(
                  (option: any) =>
                      option['model_name'] &&
                      option['model_name']
                          .toLowerCase()
                          .includes(filterString.toLowerCase())
              )
            : [];
    }

    isPdfDownloaded = false;
    isXLSDownloaded = false;
    isCSVDownloaded = false;

    onDownloadPDF() {
        // Implement your PDF download functionality here.
        //console.log('Download PDF button clicked!');
        const deviceName = this.isAuth.getDeviceId();

        try {
            let buttonPDF = document.getElementById('downloadPDFButton');
            if (buttonPDF) {
                buttonPDF.style.borderColor = 'green';
                buttonPDF.style.backgroundColor = 'green';
                buttonPDF.style.color = 'white';
            }

            let sendData = {
                device_name: deviceName,
                global_user_key: this.searchFormData.value.globalUserKey,
                event: this.selectedEventType,
                wifi_SSID: this.searchFormData.value.wiFiSSID,
                local_ip_address: this.searchFormData.value.localIp,
                global_ip_address: this.searchFormData.value.globalIp,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                download_type: 'pdf'
            };

            //console.log(sendData);

            this.service.postDownloadDeviceEventLogs(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'log.pdf'); // replace 'filename.pdf' with your desired filename
                    this.isPdfDownloaded = true;
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    onDownloadCSV() {
        const deviceName = this.isAuth.getDeviceId();
        try {
            let buttonCSV = document.getElementById('downloadCSVButton');
            if (buttonCSV) {
                buttonCSV.style.borderColor = 'green';
                buttonCSV.style.backgroundColor = 'green';
                buttonCSV.style.color = 'white';
            }

            let sendData = {
                device_name: deviceName,
                global_user_key: this.searchFormData.value.globalUserKey,
                event: this.selectedEventType,
                wifi_SSID: this.searchFormData.value.wiFiSSID,
                local_ip_address: this.searchFormData.value.localIp,
                global_ip_address: this.searchFormData.value.globalIp,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                download_type: 'csv'
            };

            //console.log(sendData);

            this.service.postDownloadDeviceEventLogs(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'log.csv'); // replace 'filename.pdf' with your desired filename
                    this.isCSVDownloaded = true;
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    onDownloadXLSX() {
        const deviceName = this.isAuth.getDeviceId();
        try {
            let buttonXLSX = document.getElementById('downloadXLSXButton');
            if (buttonXLSX) {
                buttonXLSX.style.borderColor = 'green';
                buttonXLSX.style.backgroundColor = 'green';
                buttonXLSX.style.color = 'white';
            }

            let sendData = {
                device_name: deviceName,
                global_user_key: this.searchFormData.value.globalUserKey,
                event: this.selectedEventType,
                wifi_SSID: this.searchFormData.value.wiFiSSID,
                local_ip_address: this.searchFormData.value.localIp,
                global_ip_address: this.searchFormData.value.globalIp,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                download_type: 'xlsx'
            };

            //console.log(sendData);

            this.service.postDownloadDeviceEventLogs(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'log.xlsx'); // replace 'filename.pdf' with your desired filename
                    this.isXLSDownloaded = true;
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    reloadPage() {
        this.router
            .navigateByUrl('/refresh', {skipLocationChange: true})
            .then(() => {
                this.router.navigateByUrl('/deviceEventLogs');
            });
    }

    logout() {
        this.isAuth.logout();
    }
}
