import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faIndianRupee} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {catchError, throwError} from 'rxjs';
import {FormsModule} from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ChangeDetectorRef} from '@angular/core';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private ngZONE: NgZone
    ) {}

    ngOnInit(): void {
        this.getStaffDetails();
    }

    staffFullName: string;
    staffRole: string;
    staffUserName: string;
    staffMobileNumber: number;
    staffEmailId: string;
    staffStatus: any;

    getStaffDetails() {
        try {
            this.service.getStaffProfile().subscribe({
                next: (data) => {
                    //console.log(data);
                    this.staffFullName = data['data'][0]['full_name'];
                    this.staffRole = data['data'][0]['role'];
                    this.staffUserName = data['data'][0]['staff_user_name'];
                    this.staffMobileNumber = data['data'][0]['contact_no'];
                    this.staffEmailId = data['data'][0]['email_id'];
                    this.staffStatus = data['data'][0]['staff_status'];
                },
                error: (err) => {
                    console.log(err);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    changePasswordForm = new FormGroup({
        oldPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required])
    });
    get confirmPassword(): FormControl {
        return this.changePasswordForm.get('confirmPassword') as FormControl;
    }

    get newPassword(): FormControl {
        return this.changePasswordForm.get('newPassword') as FormControl;
    }

    get oldPassword(): FormControl {
        return this.changePasswordForm.get('oldPassword') as FormControl;
    }

    changeStaffPassword() {
        try {
            //console.log(this.changePasswordForm.value);

            const sendPasswordData = {
                current_password: this.changePasswordForm.value.oldPassword,
                new_password: this.changePasswordForm.value.newPassword,
                confirm_new_password:
                    this.changePasswordForm.value.confirmPassword
            };

            this.service.postChangeStaffPassword(sendPasswordData).subscribe({
                next: (data) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.changePasswordForm.reset();
                        this.toast.success(data['message']);
                    } else {
                        this.toast.error(data['message']);
                    }
                },
                error: (err) => {
                    console.error(err);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }
}
