<pf-dropdown [hideArrow]="true" class="user-menu">
    <div slot="head">
        <a class="nav-link p-0">
            <pf-image
                [src]="user.picture"
                fallbackSrc="assets/img/default-profile.png"
                class="user-image-small shadow-none"
                width="40"
                height="40"
                alt="User Image"
                rounded="true"
            />

            <p></p>
        </a>
    </div>
    <div slot="body">
        <li class="user-header">
            <pf-image
                [src]="user.picture"
                fallbackSrc="assets/img/default-profile.png"
                class="user-image-big"
                alt="User Image"
                width="40"
                height="40"
                rounded="true"
            />

            <p class="pl-2 mt-0">
                <span>{{ user }}</span>
            </p>
        </li>
        <!-- Menu Body -->
        <li class="user-body d-flex flex-column align-items-start">
            <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat">
                Sign out
            </a>
        </li>
    </div>
</pf-dropdown>
