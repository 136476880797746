import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { IsAuthService } from '../service/is-auth.service';

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(private isAuth:IsAuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    const token = this.isAuth.getAuthToken();

    if (token) {
      // If we have a token, we set it to the header
      request = request.clone({
         setHeaders: {token: `${token}`}
      });
   }
   return next.handle(request).pipe(
  	catchError((err) => {
   	 if (err instanceof HttpErrorResponse) {
       	 if (err.status === 401) {
       	 // redirect user to the logout page
         this.isAuth.logout();
     	}


       if (err.status === 404) {
        console.error('404 Not Found', err.message);
      } else {
        console.error('An unexpected error occurred:', err);
      }

      return throwError(() => err);
 	 }
  	return throwError(()=>{
      new Error(err)
    });
	}))
    //return next.handle(request);
  }
}
