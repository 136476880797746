<!-- Brand Logo -->
<a [routerLink]="['/deviceSearch']" class="brand-link">
    <img src="assets/img/logo-white.svg" alt="TMS" class="logo" height="26px" width="auto" />
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <!-- <div class="user-panel mt-3 pb-2 d-flex" style="justify-content: start; align-items: center">
        <pf-image fallbackSrc="assets/img/default-profile.png" style="margin-left: 12px" alt="User Image" width="34"
            height="34" rounded="true" />
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user }}
            </a>
        </div>
    </div> -->

    <!-- <div class="form-inline">
        <app-sidebar-search></app-sidebar-search>
    </div> -->

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul class="nav nav-pills nav-items nav-sidebar flex-column" data-widget="treeview" role="menu"
            data-accordion="false">
            <app-menu-item *ngFor="let item of menu" [menuItem]="item" />

            <!-- <li class="nav-item dropdown-item mt-4">
                <a [routerLinkActive]="'active'" class="nav-link dropdown-link">
                    <img src="assets/img/material-symbols_payments-outline.svg" alt="">
                    <p>Payment History</p>
                    <img src="assets/img/arrow-right-white.svg" class="float-right" alt="">
                </a>
            </li> -->
            <!-- <li class="nav-item dropdown-item ">
                <a [routerLinkActive]="'active'" class="nav-link dropdown-link">
                    <img src="assets/img/company-icon.svg" alt="">
                    <div>
                        <div class="company-title">Company Name</div>
                        <div class="company-name">AdminLTE</div>
                    </div>
                    <img src="assets/img/arrow-right-white.svg" class="float-right" alt="">
                </a>
            </li> -->
        </ul>
    </nav>
</div>