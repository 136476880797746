import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {
    Component,
    ElementRef,
    NgZone,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {
    ModalDismissReasons,
    NgbModal,
    NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faIndianRupee} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {catchError, throwError} from 'rxjs';
import {FormsModule} from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {ChangeDetectorRef} from '@angular/core';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HttpErrorResponse} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {AppState} from '@/store/state';
import {ToggleSidebarMenu} from '@/store/ui/actions';

@Component({
    selector: 'app-device-search',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        FormsModule,
        MatPaginatorModule,
        NgbModule
    ],
    templateUrl: './device-search.component.html',
    styleUrl: './device-search.component.scss'
})
export class DeviceSearchComponent implements OnInit {
    constructor(
        private store: Store<AppState>,
        private isAuth: IsAuthService,
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        private ngZONE: NgZone
    ) {}

    isLoading = false; // Add this line to show the loader at the beginning
    data: any;
    displayedDeviceSearchData: any;
    total: any;
    deviceSearchDataStatus: boolean = false;

    ngOnInit(): void {}

    onToggleMenuSidebar() {
        this.store.dispatch(new ToggleSidebarMenu());
    }

    searchDeviceDataForm = new FormGroup({
        deviceName: new FormControl('', [Validators.required])
    });

    get deviceName(): FormControl {
        return this.searchDeviceDataForm.get('deviceName') as FormControl;
    }

    showDeviceSearchData() {
        try {
            this.isLoading = true;
            const sendSearchData = {
                'deviceId/deviceName':
                    this.searchDeviceDataForm.value.deviceName
            };

            this.service.postDeviceSearch(sendSearchData).subscribe({
                next: (data) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.isLoading = false;
                        this.deviceSearchDataStatus = true;
                        this.data = data['devices'];
                        //console.log(this.data.length);

                        this.total = data['devices'].length;
                        this.displayedDeviceSearchData = [...this.data];
                    } else {
                        this.isLoading = false;
                        this.toast.info(data['message']);
                    }
                },
                error: (err) => {
                    console.error(err);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    getDeviceKeys(device: any) {
        return Object.keys(device);
    }

    displayedDeviceData: any;

    filterFromTable(input: any) {
        //console.log(input);

        if (!input) {
            // If there's no input, reset to the original dataset
            this.displayedDeviceData = [...this.data];
        } else {
            const res = this.data.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.displayedDeviceData = res;
        }
    }

    deviceSearchSubmit() {
        try {
            const sendSearchData = {};
        } catch (error) {
            console.error(error);
        }
    }

    numericValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid = /^-?\d+(\.\d+)?$/.test(control.value);
            return isValid ? null : {nonNumeric: {value: control.value}};
        };
    }

    SendAmountForm = new FormGroup({
        amount: new FormControl('', [
            Validators.required,
            this.numericValidator()
        ])
    });

    get amount(): FormControl {
        return this.SendAmountForm.get('amount') as FormControl;
    }

    sendAmountToDevice1() {
        try {
            const sendData = {
                device_name: this.deviceIdForSendAmount,
                amount: this.SendAmountForm.value.amount
            };

            //console.log(sendData);

            this.service
                .postSendAmountToDevice(sendData)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] == true) {
                        this.toast.success(data['message']);
                        this.SendAmountForm.reset();
                        this.ngOnInit();
                    } else {
                        this.toast.error(data['message']);
                        this.SendAmountForm.reset();
                        this.ngOnInit();
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    closeResult: any;
    deviceIdForSendAmount: any;

    openSendAmount(sendAmountToDevice: TemplateRef<any>, deviceId: any) {
        //console.log('send amount', deviceId);
        this.deviceIdForSendAmount = deviceId;
        this.modalService
            .open(sendAmountToDevice, {
                ariaLabelledBy: `sendAmount_${deviceId}`
            })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    submitAndClose(modal: any) {
        // perform your actions here, like sending the form data

        // then close the modal
        //this.modalReference.close('Save Clicked');
        this.modalService.dismissAll();
    }

    private getDismissReason(reason: any): string {
        switch (reason) {
            case ModalDismissReasons.ESC:
                return 'by pressing ESC';
            case ModalDismissReasons.BACKDROP_CLICK:
                return 'by clicking on a backdrop';
            default:
                return `with: ${reason}`;
        }
    }

    // json command send to device

    commandForm = new FormGroup({
        commandName: new FormControl('', [Validators.required]),
        jsonPayload: new FormControl('', [Validators.required])
    });

    get commandName(): FormControl {
        return this.commandForm.get('commandName') as FormControl;
    }

    get jsonPayload(): FormControl {
        return this.commandForm.get('jsonPayload') as FormControl;
    }

    showCommands: any[] = [];

    changeCommandNameFromOption: any;

    changeCommandName(commandName: any) {
        //console.log(commandName);
        this.changeCommandNameFromOption = commandName;

        const selectedCommand = this.showCommands.find(
            (c) => c.command_name === commandName
        );

        if (selectedCommand) {
            //this.editDeviceNameForm.controls.editDeviceName.setValue(editDeviceName);
            this.commandForm.controls.jsonPayload.setValue(
                selectedCommand.JSON_payload
            );
        } else {
            // If not found, clear the jsonPayload form control
            this.commandForm.controls.jsonPayload.setValue('');
        }
    }

    deviceIdForCommand: any;

    openSendDeviceId(sendDeviceId: TemplateRef<any>, deviceId: any) {
        //console.log('device name', deviceId);
        this.isLoading = true;
        this.deviceIdForCommand = deviceId;
        const sendId = {
            device_name: deviceId
        };

        this.service.getJSONCommands(sendId).subscribe((data: any) => {
            //console.log(data);
            if (data['status'] === true) {
                this.isLoading = false;
                this.showCommands = data.data;

                this.modalService
                    .open(sendDeviceId, {
                        ariaLabelledBy: `sendDeviceId_${deviceId}`
                    })
                    .result.then(
                        (result) => {
                            this.closeResult = `Closed with: ${result}`;
                        },
                        (reason) => {
                            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                        }
                    );
            } else {
                this.isLoading = false;
                this.toast.info(data['message']);
            }
        });
    }

    sendCommandToDevice() {
        try {
            const sendCommand = {
                device_name: this.deviceIdForCommand,
                json_payload: this.commandForm.value.jsonPayload
            };

            //console.log(sendCommand);
            this.service
                .postSendJSONCommandToDevice(sendCommand)
                .subscribe((data: any) => {
                    //console.log(data);
                    if (data['status'] === true) {
                        this.commandForm.reset();
                        this.toast.success(data['message']);
                    } else {
                        this.toast.error(data['message']);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    //send text to speech to device

    sendTTSToDeviceForm = new FormGroup({
        audioText: new FormControl('', [Validators.required])
    });

    get audioText(): FormControl {
        return this.sendTTSToDeviceForm.get('audioText') as FormControl;
    }

    sendTTSToDevice() {
        try {
            let sendData = {
                device_name: this.audioSendDeviceId,
                text: this.sendTTSToDeviceForm.value.audioText
            };

            this.service
                .postSendTextToSpeechToDevice(sendData)
                .pipe(
                    catchError((error) => {
                        // Log the error or handle it in a user-friendly way
                        console.error('Error occurred:', error);

                        // Optionally, you can return a default value to the subscriber
                        // return of(defaultValue);

                        // Or rethrow the error if you want to handle it further upstream
                        return throwError(
                            () =>
                                new Error(
                                    'An error occurred while sending audio to the device'
                                )
                        );
                    })
                )
                .subscribe({
                    next: (data: any) => {
                        // Handle the successful response
                        //console.log(data);
                        if (data['status'] === true) {
                            this.toast.success(data['message']);
                        }
                    },
                    error: (error) => {
                        // This block is for handling errors that might occur after catchError
                        // For example, if you rethrow the error in catchError, it will be caught here
                        console.error('Failed to handle:', error);
                        this.toast.error(error);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    audioSendDeviceId: any;
    audioSend1(audioSend: TemplateRef<any>, deviceId: any) {
        this.audioSendDeviceId = deviceId;

        this.modalService
            .open(audioSend, {ariaLabelledBy: `sendAudio_${deviceId}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    // payment history

    deviceHistory(deviceId: any) {
        //console.log(deviceId);
        this.isAuth.setDeviceId(deviceId);
        this.router.navigate(['/paymentHistory']);
    }

    // dveice event logs

    deviceEventLogHistory(deviceId: any) {
        //console.log(deviceId);
        this.isAuth.setDeviceId(deviceId);
        this.router.navigate(['/deviceEventLogs']);
    }

    //mqtt status

    mqttResponseTrue = false;

    connectedDevice: boolean;
    cleanStart: boolean;
    enableAuthentication: boolean;
    connectedAt: any;
    createdAt: any;
    awaitingRelCnt: number;
    awaitingRelMax: number;
    clientIdMqtt: string;
    expiryInterval: number;
    heapSize: number;
    inflightCnt: number;
    inflightMax: number;
    ipAddress: any;
    isBridge: boolean;
    isPersistent: boolean;
    keepAlive: number;
    listener: string;
    mailboxLen: number;
    mountPoint: any;
    mQueueDropped: number;
    mQueueLen: number;
    mQueueMax: number;
    node: string;
    port: number;
    protoName: string;
    protoVersion: number;
    recvCnt: number;
    recvMsg: number;
    recvMsgDropped: number;
    recvMsgDroppedAwaitPubRelTimeOut: any;
    recvMsgQos0: number;
    recvMsgQos1: number;
    recvMsgQos2: number;
    recvOct: number;
    recvPkt: number;
    reductions: number;
    sendCnt: number;
    sendMsg: number;
    sendMsgDropped: number;
    sendMsgDroppedExpired: number;
    sendMsgDroppedQueueFull: number;
    sendMsgDroppedTooLarge: number;
    sendMsgQos0: number;
    sendMsgQos1: number;
    sendMsgQos2: number;
    sendOct: number;
    sendPkt: number;
    subscriptionsCount: number;
    subscriptionsMax: string;
    mqttUserName: string;
    zone: string;

    subStatusClientId: string;
    subStatusNode: string;
    subStatusTopic: string;
    subStatusNl: number;
    subStatusQos: number;
    subStatusRap: number;
    subStatusRh: number;

    subStatusData: any;
    clientStatus: any;

    mqttServerNotConnected: any;

    viewMqttDetails(mqttDetails: TemplateRef<any>, deviceName: any) {
        this.isLoading = true;
        //console.log(deviceId);

        const send = {
            device_name: deviceName
        };

        this.service.postMQTTDeviceStatus(send).subscribe({
            next: (data: any) => {
                this.mqttResponseTrue = true;
                //console.log(data);

                //console.log(data['client_status']['enable_authn']);

                if (data['status'] == true) {
                    //console.log(data['client_status']['recv_msg.dropped.await_pubrel_timeout']);
                    //this.clientIdMqtt = data['client_status']['clientid'];

                    this.mountPoint = data['client_status']['mountpoint'];
                    this.recvMsgDroppedAwaitPubRelTimeOut =
                        data['client_status'][
                            'recv_msg.dropped.await_pubrel_timeout'
                        ];
                    this.clientStatus = data['client_status'];
                    this.subStatusData = data['client_subscriptions'][0];
                    this.recvMsgDropped =
                        data['client_status']['recv_msg.dropped'];
                    this.recvMsgQos0 = data['client_status']['recv_msg.qos0'];
                    this.recvMsgQos1 = data['client_status']['recv_msg.qos1'];
                    this.recvMsgQos2 = data['client_status']['recv_msg.qos2'];
                    this.sendMsgDropped =
                        data['client_status']['send_msg.dropped'];
                    this.sendMsgDroppedExpired =
                        data['client_status']['send_msg.dropped.expired'];
                    this.sendMsgDroppedQueueFull =
                        data['client_status']['send_msg.dropped.queue_full'];
                    this.sendMsgDroppedTooLarge =
                        data['client_status']['send_msg.dropped.too_large'];
                    this.sendMsgQos0 = data['client_status']['send_msg.qos0'];
                    this.sendMsgQos1 = data['client_status']['send_msg.qos1'];
                    this.sendMsgQos2 = data['client_status']['send_msg.qos2'];

                    this.subStatusClientId =
                        data['client_subscriptions'][0]['clientid'];
                    this.subStatusNode =
                        data['client_subscriptions'][0]['node'];
                    this.subStatusTopic =
                        data['client_subscriptions'][0]['topic'];
                    this.subStatusNl = data['client_subscriptions'][0]['nl'];
                    this.subStatusQos = data['client_subscriptions'][0]['qos'];
                    this.subStatusRap = data['client_subscriptions'][0]['rap'];
                    this.subStatusRh = data['client_subscriptions'][0]['rh'];

                    this.isLoading = false;
                }
            },
            error: (err: any) => {
                this.isLoading = false;
                this.mqttResponseTrue = false;
                if (err instanceof HttpErrorResponse) {
                    // Check if the error status code is specifically 404
                    //console.log("hello",err.error.message);
                    if (err.status === 404) {
                        //console.log('Resource not found:', err.error.message);
                        this.toast.error(err.error.message);
                        this.mqttServerNotConnected = err.error.message;
                    } else if (err.error && err.error.message) {
                        // Handle cases where the server returns an error message in a known format
                        //console.log(err.error.message);
                    } else {
                        // Handle other types of errors generically
                        //console.log('An error occurred:', err.error.message || err);
                        this.toast.error(
                            'An error occurred:',
                            err.error.message || err
                        );
                    }
                } else {
                    // This block catches errors that are not HTTP responses (unlikely in this context)
                    //console.log('An unexpected error occurred:', err);
                    this.toast.error('An unexpected error occurred:', err);
                }
            }
        });

        this.modalService
            .open(mqttDetails, {ariaLabelledBy: `mqttDetails_${deviceName}`})
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    // api log

    deviceAPILogHistory(deviceId: any) {
        //console.log(deviceId);
        this.isAuth.setDeviceId(deviceId);
        this.router.navigate(['/apiLogs']);
    }
}
