import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import myUrl from '../../assets/url.json';
import {
    Observable,
    catchError,
    map,
    retry,
    switchMap,
    tap,
    throwError
} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {
        let auth_token = localStorage.getItem('Token');

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth_token}`
        });

        const requestOptions = {headers: headers};
    }

    apiUrl: string = '../../assets/newUrl.json';

    getUrlByOperation(operationType: string): Observable<string> {
        return this.http.get<any[]>(this.apiUrl).pipe(
            tap((apis) => {
                //console.log("Loaded APIs:", apis); // Check what's actually loaded
            }),
            map((apis) => {
                const api = apis.find((api) => {
                    //console.log("Checking API:", api.operationType); // See what's being checked
                    return api.operationType === operationType;
                });

                if (!api) {
                    console.error(
                        `No API found for operation type: ${operationType}`
                    );
                    throw new Error(
                        `No API found for operation type: ${operationType}`
                    );
                }

                return api.url;
            })
        );
    }

    // constructor(private http: HttpClient) {
    //     let auth_token = localStorage.getItem('Token');

    //     const headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${auth_token}`
    //     });

    //     const requestOptions = {headers: headers};
    // }

    // this project's apis

    postLoginAPI(param: any) {
        //return this.http.post(ApiService.login, param);
        return this.getUrlByOperation('login').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeviceSearch(param: any) {
        //return this.http.post(ApiService.login, param);
        return this.getUrlByOperation('deviceSearch').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendAmountToDevice(param: any) {
        //return this.http.post(ApiService.login, param);
        return this.getUrlByOperation('sendAmountToDevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendJSONCommandToDevice(param: any) {
        //return this.http.post(ApiService.login, param);
        return this.getUrlByOperation('sendJSONCommandToDevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSendTextToSpeechToDevice(param: any) {
        return this.getUrlByOperation('sendTextToSpeechToDevice').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getJSONCommands(param: any) {
        return this.getUrlByOperation('getJSONCommands').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postChangeStaffPassword(param: any) {
        return this.getUrlByOperation('changeStaffPassword').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    getStaffProfile() {
        return this.getUrlByOperation('showStaffProfile').pipe(
            switchMap((url) => {
                return this.http.get(url);
            })
        );
    }

    postPaymentHistory(param: any) {
        return this.getUrlByOperation('paymentHistory').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSearchPaymentHistory(param: any) {
        return this.getUrlByOperation('searchPaymentHistory').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDownloadPaymentHistory(param: any) {
        return this.getUrlByOperation('downloadPaymentHistory').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {responseType: 'blob'});
            })
        );
    }

    postAPILogs(param: any) {
        return this.getUrlByOperation('apiLogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postSearchAPILogs(param: any) {
        return this.getUrlByOperation('searchAPILogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDownloadAPILogs(param: any) {
        return this.getUrlByOperation('downloadAPILogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {responseType: 'blob'});
            })
        );
    }

    postDeviceEventLogs(param: any) {
        return this.getUrlByOperation('deviceEventLogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDeviceSearchEventLogs(param: any) {
        return this.getUrlByOperation('deviceSearchEventLogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }

    postDownloadDeviceEventLogs(param: any) {
        return this.getUrlByOperation('deviceDownloadEventLogs').pipe(
            switchMap((url) => {
                return this.http.post(url, param, {responseType: 'blob'});
            })
        );
    }

    postMQTTDeviceStatus(param: any) {
        return this.getUrlByOperation('mqttDeviceStatus').pipe(
            switchMap((url) => {
                return this.http.post(url, param);
            })
        );
    }
}
