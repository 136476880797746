<!-- Main content -->
<section class="content">
    <div class="container-fluid">


        <div class="row align-items-center mb-3">

            <div class="col-sm-6 col-xl-7 col-xxl-7  mb-2">
                <h2 class="mb-0">API History ({{ total }})</h2>
            </div>
            <div class="col-sm-6 col-xl-5 col-xxl-5 mb-2 d-flex align-items-center justify-content-end">
                <div class="search-container d-flex align-items-center mr-4">
                    <label for="searchDevicesInput" class="form-label mb-0 mr-2 "><img
                            src="assets/img/ion_search-outline.svg" alt=""></label>
                    <input type="text" id="searchDevicesInput" placeholder="Search here..."
                        (input)="filterFromTable($event.target.value)" class="form-control h-[44px]">
                </div>

                <button type="button" id="searchBtn" (click)="toggleSearchForm()" class="btn btn-primary">
                    filter
                </button>
            </div>

            <div class="col-12">

                <div class="card mt-4" *ngIf="displaySearchForm">
                    <div class="card-header">
                        <h4 class="card-title">Search Filter</h4>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <!-- Default box -->
                        <form [formGroup]="searchFormData" (ngSubmit)="onSearch()" class="flex">
                            <div class="container-fluid">
                                <div class="row">


                                    <div class="col-6 col-lg-5 col-xl-3 col-xxl-2">
                                        <div class="form-group">
                                            <label class="fw-normal" for="fromDate">From Date:</label>
                                            <input type="date" id="fromDate" formControlName="fromDate" name="fromDate"
                                                class="form-control" />
                                        </div>
                                    </div>

                                    <div class="col-6 col-lg-5 col-xl-3 col-xxl-2">
                                        <div class="form-group">
                                            <label class="fw-normal" for="toDate">To Date:</label>
                                            <input type="date" id="toDate" formControlName="toDate" name="toDate"
                                                class="form-control" />
                                        </div>
                                    </div>

                                    <div class="col-4 col-lg-2 col-xl-2 col-xxl-1">
                                        <div class="form-group">
                                            <label for="ipAddress" class="fw-normal d-block">Apply Date</label>
                                            <label class="switch" for="applyDate">
                                                <input type="checkbox" id="applyDate" value="" #myCheckbox unchecked />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <hr>

                                <div class="flex w-full flex-wrap">

                                    <button type="button" *ngIf="showDownloadButtons" id="downloadPDFButton"
                                        class="btn px-4 btn-outline-primary mr-2" (click)="onDownloadPDF()">
                                        Download .PDF
                                    </button>
                                    <button type="button" *ngIf="showDownloadButtons" id="downloadCSVButton"
                                        class="btn px-3 btn-outline-primary mr-2" (click)="onDownloadCSV()">
                                        Download .CSV
                                    </button>
                                    <button type="button" *ngIf="showDownloadButtons" id="downloadXLSXButton"
                                        class="btn btn-outline-primary mr-2" (click)="onDownloadXLSX()">
                                        Download .XLSX
                                    </button>


                                    <button type="submit" id="searchButton" (click)="changeColor($event)"
                                        class="btn btn-primary mr-2 ml-auto">
                                        Submit
                                    </button>
                                    <button type="submit" class="" id="cancelSearch" (click)="reloadPage()"
                                        class="btn  btn-outline-secondary ">
                                        Cancel Search
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- /.card -->
                </div>
            </div>


        </div>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">

                    <table id="example2" class="table  table-hover">
                        <thead>
                            <tr>
                                <th *ngFor="let field of prop">
                                    <span> {{ capitalizeAndReplace(field) }}
                                        <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                        <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let datas of displayedPaymentData; let i = index">
                            <tr>
                                <ng-container *ngFor="let val of prop">
                                    <td>
                                        <span> {{ datas[val] }} </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <mat-paginator class="mr-3" (page)="handlePage($event)" [length]="length" [pageSize]="pageSize"
                    [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true">
                </mat-paginator>
            </div>
        </div>
        <!-- /.card -->
    </div>
</section>
<!-- /.content -->

<!-- In your HTML -->
<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>
<!-- Your other HTML goes here -->