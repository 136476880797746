import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PaymenthistoryComponent} from '@pages/paymenthistory/paymenthistory.component';
import {DeviceSearchComponent} from '@pages/device-search/device-search.component';
import {ApilogsComponent} from '@pages/apilogs/apilogs.component';
import {DeviceeventlogsComponent} from '@pages/deviceeventlogs/deviceeventlogs.component';
import {DummyComponent} from '@pages/dummy/dummy.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'deviceSearch',
                component: DeviceSearchComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'paymentHistory',
                component: PaymenthistoryComponent
            },
            {
                path: 'apiLogs',
                component: ApilogsComponent
            },
            {
                path: 'deviceEventLogs',
                component: DeviceeventlogsComponent
            },
            {path: 'refresh', component: DummyComponent} // Add this line
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: 'devviceSearch'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
