<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Device Event Log</h2>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Device Event Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Device Events History</h2>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h3 class="text-2xl">Total: {{ total }} Records</h3>
                            </div>
                            <div class="d-flex flex-column text-right">
                                <button type="button" id="searchBtn" (click)="toggleSearchForm()"
                                    class="btn btn-primary mb-2">
                                    Search
                                </button>
                                <div class="search-container d-flex align-items-center ml-2">
                                    <label for="searchDevicesInput" class="form-label mt-2 mr-2">Search:</label>
                                    <input type="text" id="searchDevicesInput" placeholder="Search Devices..."
                                        (input)="filterFromTable($event.target.value)" class="form-control">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="displaySearchForm">
                            <form [formGroup]="searchFormData" (ngSubmit)="onSearch()" class="d-flex">
                                <div class="container-fluid">
                                    <div class="d-flex">
                                        <div class="form-group">
                                            <label for="fromDate" style="margin-top: 20px">From Date:</label>
                                            <input type="date" id="fromDate" formControlName="fromDate" name="fromDate"
                                                class="form-control"
                                                style="width: 150px; font-size: 0.8rem; height: 56px; margin-top: 8px;" />
                                        </div>
                                        <div class="form-group ml-3">
                                            <label for="toDate" style="margin-top: 20px">To Date:</label>
                                            <input type="date" id="toDate" formControlName="toDate" name="toDate"
                                                class="form-control"
                                                style="width: 150px; font-size: 0.8rem; height: 56px; margin-top: 8px;" />
                                        </div>
                                        <div class="form-group ml-3" style="flex: 0 0 auto">
                                            <label class="switch" for="applyDate" style="margin-top: 60px">
                                                <input type="checkbox" id="applyDate" value="" #myCheckbox unchecked />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="form-group ml-3" style="flex: 0 0 auto; margin-top: 20px;">
                                            <label for="wiFiSSID">WI-FI SSID</label>
                                            <input type="text" id="wiFiSSID" formControlName="wiFiSSID"
                                                class="form-control"
                                                style="width: 150px; font-size: 0.8rem; height: 56px; margin-top: 7px;">
                                        </div>
                                        <div class="form-group ml-3" style="flex: 0 0 auto; margin-top: 20px;">
                                            <label for="globalUserKey">Global / User API Key</label>
                                            <input type="text" id="globalUserKey" formControlName="globalUserKey"
                                                class="form-control"
                                                style="width: 270px; font-size: 0.8rem; height: 56px; margin-top: 7px;">
                                        </div>
                                        <div class="form-group ml-3" style="flex: 0 0 auto; margin-top: 20px;">
                                            <label for="deviceId">Device ID</label>
                                            <input type="text" id="deviceId" formControlName="deviceId"
                                                class="form-control"
                                                style="width: 150px; font-size: 0.8rem; height: 56px; margin-top: 7px;">
                                        </div>
                                        <div class="form-group ml-3" style="flex: 0 0 auto; margin-top: 20px;">
                                            <label for="localIp">Local IP Address</label>
                                            <input type="text" id="localIp" formControlName="localIp"
                                                class="form-control"
                                                style="width: 200px; font-size: 0.8rem; height: 56px; margin-top: 7px;">
                                        </div>
                                        <div class="form-group ml-3" style="flex: 0 0 auto; margin-top: 20px;">
                                            <label for="globalIp">Global IP Address</label>
                                            <input type="text" id="globalIp" formControlName="globalIp"
                                                class="form-control"
                                                style="width: 200px; font-size: 0.8rem; height: 56px; margin-top: 7px;">
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3">
                                        <div class="form-group">
                                            <label for="editEventTypeModel" class="col-form-label"
                                                style="margin-top: 10px;">Event Type</label>
                                            <div style="width: 150px;">
                                                <mat-form-field style="width: 100%;">
                                                    <mat-label>Select an option</mat-label>
                                                    <mat-select [value]="selectedEventType"
                                                        (selectionChange)="changeEventType($event.value)">
                                                        <mat-option>None</mat-option>
                                                        <mat-option *ngFor="let datas1 of showEventTypeModel"
                                                            [value]="datas1['eventType']">
                                                            {{ datas1['eventType'] }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3">
                                        <button type="submit" id="searchButton" class="btn btn-primary mr-1">
                                            Submit
                                        </button>
                                        <button type="button" *ngIf="showDownloadButtons" id="downloadPDFButton"
                                            [class.btn-success]="isPdfDownloaded" class="btn btn-secondary mr-1"
                                            (click)="onDownloadPDF()">
                                            Download .PDF
                                        </button>
                                        <button type="button" *ngIf="showDownloadButtons" id="downloadCSVButton"
                                            [class.btn-success]="isCSVDownloaded" class="btn btn-secondary mr-1"
                                            (click)="onDownloadCSV()">
                                            Download .CSV
                                        </button>
                                        <button type="button" *ngIf="showDownloadButtons" id="downloadXLSXButton"
                                            class="btn btn-secondary mr-1" [class.btn-success]="isXLSDownloaded"
                                            (click)="onDownloadXLSX()">
                                            Download .XLSX
                                        </button>
                                        <button type="button" id="cancelSearch" class="btn btn-primary ml-3"
                                            (click)="reloadPage()">
                                            Cancel Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div style="overflow-x: auto; white-space: nowrap;">
                            <table id="example2" class="table table-bordered table-hover mt-2">
                                <thead>
                                    <tr>
                                        <th *ngFor="let field of prop">
                                            {{ capitalizeAndReplace(field) }}
                                            <i class="fas fa-sort-up" (click)="sortData(field, 'asc')"></i>
                                            <i class="fas fa-sort-down" (click)="sortData(field, 'desc')"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let datas of displayedLogData; let i = index">
                                        <ng-container *ngFor="let val of prop">
                                            <td>{{ datas[val] }}</td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="ml-3">Current Page: {{ pageIndex + 1 }}</div>
                        <mat-paginator class="mr-3" (page)="handlePage($event)" [length]="length" [pageSize]="pageSize"
                            [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- /.content -->






<!-- In your HTML -->
<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>
<!-- Your other HTML goes here -->