import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsAuthService {

  private deviceId:any;

  constructor(private router:Router) { }

  isAuthenticated() {
    //console.log(localStorage.getItem('Token'));
    return localStorage.getItem('Token') !== null;
    //return !!localStorage.getItem('Token');
    //return true;
  }

  logout() {
    //this._firebaseAuth.signOut();    
    localStorage.removeItem('Token');
    //localStorage.removeItem('LoginId');
    //localStorage.removeItem('UserName');
    this.router.navigate(['/login']);
  }

  getAuthToken() {
    return localStorage.getItem('Token')
    }


    setDeviceId(id: any) {
      this.deviceId = id;
    }
  
    getDeviceId() {
      return this.deviceId;
    }

}
