<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h2>Search Device</h2>
            </div>
            <!-- <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/device-search">Home</a></li>
                    <li class="breadcrumb-item active">Device Search</li>
                </ol>
            </div> -->
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<!-- Main content -->
<section class="content">
    <form [formGroup]="searchDeviceDataForm" (ngSubmit)="showDeviceSearchData()" method="post">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-title">Device Search</h2>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="flex justify-between items-center mb-3">
                                <div class="flex items-center">
                                    <!-- Added flex to align label and input horizontally -->
                                    <label for="deviceName"
                                        class="mr-4 block text-gray-900 sm:text-sm sm:leading-6">Device Id / Device
                                        Name</label>
                                    <input type="text" id="deviceName" name="deviceName" formControlName="deviceName"
                                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Enter Device Id/Device Name" />
                                    <button type="submit" class="btn btn-primary ml-3">
                                        SEARCH
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->

        <!-- /.container-fluid -->
    </form>
</section>
<!-- /.content -->

<section *ngIf="deviceSearchDataStatus === true" class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header text-center">
                        <h2 class="card-title">Device Search Data</h2>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">


                        </div>

                        <div class="d-flex justify-content-center">
                            <div class="device-container">
                                <div *ngFor="let device of displayedDeviceSearchData"
                                    class="device-data my-3 p-3 border rounded">
                                    <div class="device-info">
                                        <div *ngFor="let key of getDeviceKeys(device)" class="key-value-pair mb-2">
                                            <strong class="key">{{ key }}:</strong>
                                            <span class="value"
                                                [ngClass]="{'text-success': key === 'device_status' && device[key] === 1, 'text-danger': key === 'device_status' && device[key] === 0}">
                                                <!-- Conditional display for device_status -->
                                                <ng-container *ngIf="key === 'device_status'">
                                                    {{ device[key] === 1 ? 'Active' : 'Deactive' }}
                                                </ng-container>
                                                <!-- Display other keys as usual -->
                                                <ng-container *ngIf="key !== 'device_status'">
                                                    {{ device[key] }}
                                                </ng-container>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Icons section -->
                                    <div class="icons text-center mt-3">
                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_id'] }}"
                                            id="sendAmount_{{ device['device_id'] }}" (click)="
                                            openSendAmount(
                                                sendAmountToDevice,
                                                device['device_name']
                                            )
                                        " ng-click="getValue($event)" matTooltip="Send Amount">
                                            <i class="fas fa-rupee-sign"></i>
                                        </button>

                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_name'] }}"
                                            id="sendDeviceId_{{ device['id'] }}" (click)="
                                            openSendDeviceId(
                                                sendDeviceId,
                                                device['device_name']
                                            )
                                        " ng-click="getValue($event)" matTooltip="Send Command">
                                            <i class="fas fa-desktop"></i>
                                        </button>

                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_name'] }}"
                                            id="sendAudio_{{ device['device_name'] }}" (click)="
                                            audioSend1(
                                                audioSend,
                                                device['device_name']
                                            )
                                        " ng-click="getValue($event)" matTooltip="Send Audio To Device">
                                            <i class="fas fa-volume-up"></i>
                                        </button>


                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_name'] }}"
                                            id="sendDeviceId_{{ device['device_name'] }}" (click)="
                                            deviceHistory(
                                                device['device_name']
                                                )
                                        " ng-click="getValue($event)"
                                            matTooltip="Go to payment history of this Device">
                                            <i class="fas fa-history"></i>
                                        </button>


                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_name'] }}"
                                            id="sendDeviceId_{{ device['device_name'] }}" (click)="
                                            deviceEventLogHistory(
                                                device['device_name']
                                                )
                                        " ng-click="getValue($event)"
                                            matTooltip="Go to Event Log history of this Device">
                                            <i class="fa fa-terminal"></i>
                                        </button>


                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_name'] }}"
                                            id="mqttDetails_{{ device['device_name'] }}" (click)="deviceAPILogHistory(
                                                device['device_name']
                                            )" ng-click="getValue($event)" matTooltip="Go to API Logs of this Device">
                                            <i class="fa fa-chevron-right"></i>
                                        </button>


                                        <button class="btn-sm btn-outline mt-1 ml-1" value="{{ device['device_name'] }}"
                                            id="mqttDetails_{{ device['device_name'] }}" (click)="viewMqttDetails(
                                                mqttDetails,
                                                device['device_name']
                                            )" ng-click="getValue($event)" matTooltip="View MQTT Status">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="23"
                                                viewBox="0 0 24 24">
                                                <path fill="currentColor"
                                                    d="M10.657 23.994h-9.45A1.21 1.21 0 0 1 0 22.788v-9.18h.071c5.784 0 10.504 4.65 10.586 10.386m7.606 0h-4.045C14.135 16.246 7.795 9.977 0 9.942V6.038h.071c9.983 0 18.121 8.044 18.192 17.956m4.53 0h-.97C21.754 12.071 11.995 2.407 0 2.372v-1.16C0 .55.544.006 1.207.006h7.64C15.733 2.49 21.257 7.789 24 14.508v8.291c0 .663-.544 1.195-1.207 1.195M16.713.006h6.092A1.19 1.19 0 0 1 24 1.2v5.914c-.91-1.242-2.046-2.65-3.158-3.762C19.588 2.11 18.122.987 16.714.005Z" />
                                            </svg>
                                        </button>


                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>


<ng-template #mqttDetails let-modal>
    <form method="post">
        <div class="modal-header">
            <h4 class="modal-title font-medium" id="modal-basic-title">
                Device Connection Information
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>

        <div *ngIf="mqttResponseTrue === true">
            <button class="btn btn-success mt-2 ml-3">Device Connected With MQTT</button>
        </div>

        <div *ngIf="mqttResponseTrue === false">
            <button class="btn btn-danger mt-2 ml-3">Device Not Connected With MQTT</button>
        </div>

        <div class="modal-body scrollable-content" *ngIf="mqttResponseTrue === true">

            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <ul id="clientStatus">


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client
                                Id</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.clientid}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName"
                                class="block font-medium leading-6 text-gray-900">Connected</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p id="connected" [ngClass]="{
                                    'status-online': clientStatus.connected === true,
                                    'status-offline': clientStatus.connected === false
                                }">{{clientStatus.connected}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Clean
                                Start</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p id="connected" [ngClass]="{
                                    'status-online': clientStatus.clean_start === true,
                                    'status-offline': clientStatus.clean_start === false
                                }">{{clientStatus.clean_start}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Enable
                                Authentication</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p [ngClass]="{
                                    'status-online': clientStatus.enable_authn === true,
                                    'status-offline': clientStatus.enable_authn === false
                                }">{{clientStatus.enable_authn}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">IP
                                Address</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.ip_address}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Connected
                                At</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.connected_at}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Created
                                At</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.created_at}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Expiry
                                Interval</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.expiry_interval}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Awaiting Rel
                                Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.awaiting_rel_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Awaiting Rel
                                Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.awaiting_rel_max}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Heap
                                Size</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.heap_size}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Inflight
                                Cnt</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.inflight_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Inflight
                                Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.inflight_max}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Is
                                Bridge</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.is_bridge}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Is
                                Persistent</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.is_persistent}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Keep
                                Alive</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.keepalive}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName"
                                class="block font-medium leading-6 text-gray-900">Listener</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.listener}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mailbox
                                Len</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mailbox_len}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mount
                                Point</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{mountPoint || 'null'}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mqueue
                                Dropped</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mqueue_dropped}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mqueue
                                Len</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mqueue_len}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Mqueue
                                Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.mqueue_max}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Node</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.node}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Port</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.port}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Proto
                                Name</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.proto_name}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Proto
                                Version</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.proto_ver}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive
                                Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive
                                Message</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_msg}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message
                                Dropped</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgDropped}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message
                                Dropped Await Pubrel Timeout</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgDroppedAwaitPubRelTimeOut}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message
                                Qos 0</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgQos0}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message
                                Qos 1</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgQos1}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive Message
                                Qos 2</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{recvMsgQos2}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive
                                Oct</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_oct}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Receive
                                Packets</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.recv_pkt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName"
                                class="block font-medium leading-6 text-gray-900">Reductions</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.reductions}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send
                                Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send
                                Messages</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_msg}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Dropped</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDropped}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Dropped Expired</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDroppedExpired}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Dropped Queue Full</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDroppedQueueFull}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Dropped Too Large</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgDroppedTooLarge}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Qos 0</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgQos0}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Qos 1</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgQos1}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send Message
                                Qos 2</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{sendMsgQos2}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send
                                Oct</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_oct}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Send
                                Packets</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.send_pkt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Subscriptions
                                Count</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.subscriptions_cnt}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Subscriptions
                                Max</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.subscriptions_max}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName"
                                class="block font-medium leading-6 text-gray-900">Username</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.username}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Zone</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{clientStatus.zone}}</p>
                            </div>
                        </div>

                    </ul>
                </div>
            </div>
            &nbsp;


            <div>
                <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Subscription Status</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <ul id="clientStatus">


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Client
                                Id</label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <p>{{subStatusData.clientid}}</p>
                            </div>
                        </div>



                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Node</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p>{{subStatusData.node}}</p>
                            </div>
                        </div>


                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Topic</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p>{{subStatusData.topic}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Nl</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p>{{subStatusData.nl}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Qos</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p>{{subStatusData.qos}}</p>
                            </div>
                        </div>




                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Rap</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p>{{subStatusData.rap}}</p>
                            </div>
                        </div>

                        <div>
                            <label for="editGroupName" class="block font-medium leading-6 text-gray-900">Rh</label>
                            <div class="relative mt-2 rounded-md shadow-sm">

                                <p>{{subStatusData.rh}}</p>
                            </div>
                        </div>





                    </ul>
                </div>
            </div>


        </div>

        <div class="modal-body scrollable-content" *ngIf="mqttResponseTrue === false">
            <div>
                <label for="editGroupName"
                    class="block font-medium leading-6 text-red-700">{{mqttServerNotConnected}}</label>

            </div>
            &nbsp;
        </div>

        <div class="modal-footer">

        </div>
    </form>
</ng-template>


<ng-template #audioSend let-modal>
    <form [formGroup]="sendTTSToDeviceForm" (ngSubmit)="sendTTSToDevice()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                Send text to speech audio to device
            </h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="editDeviceName" class="block text-2xl font-medium leading-6 text-gray-900">Text</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="audioText" id="audioText" formControlName="audioText"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter text to send to device" />
                </div>
            </div>
            &nbsp;
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Send
            </button>
        </div>
    </form>
</ng-template>

<ng-template #sendAmountToDevice let-modal>
    <form [formGroup]="SendAmountForm" (ngSubmit)="sendAmountToDevice1()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Send Amount</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="amount" class="block text-2xl font-medium leading-6 text-gray-900">Enter Amount</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <!-- <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span class="text-gray-500 sm:text-sm">$</span>
                    </div> -->
                    <input type="text" name="amount" id="amount" formControlName="amount"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Enter Amount" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Send Amount
            </button>
        </div>
    </form>
</ng-template>

<ng-template #sendDeviceId let-modal>
    <form [formGroup]="commandForm" (ngSubmit)="sendCommandToDevice()" method="post">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Send JSON Command</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div>
                <label for="commandName" class="block text-2xl font-medium leading-6 text-gray-900">Command Name</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <mat-form-field class="w-96">
                        <mat-label>Select a Command</mat-label>
                        <mat-select (selectionChange)="changeCommandName($event.value)" formControlName="commandName">
                            <mat-option *ngFor="let datas1 of showCommands" value="{{ datas1['command_name'] }}">{{
                                datas1['command_name'] }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <label for="jsonPayload" class="block text-2xl font-medium leading-6 text-gray-900">Command JSON
                    Pay-Load</label>
                <div class="relative mt-2 rounded-md shadow-sm">
                    <textarea name="jsonPayload" id="jsonPayload" formControlName="jsonPayload"
                        class="block w-96 rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        cols="30" rows="10" placeholder="Enter JSON Command Details"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="modal.close('Save click')">
                Send JSON Command
            </button>
        </div>
    </form>
</ng-template>

<div class="loader-container" *ngIf="isLoading">
    <div class="lds-hourglass"></div>
</div>