<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>

        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <pf-image class="user-img" src="/assets/img/default-profile.png" alt="User profile picture"
                                width="100" height="100" rounded="true"></pf-image>
                        </div>

                        <h3 class="profile-username text-center">
                            {{staffFullName}}
                        </h3>

                        <p class="text-muted text-center text-green">{{staffRole}}</p>

                        <!-- <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>Followers</b>
                                <a class="float-right">1,402</a>
                            </li>
                            <li class="list-group-item">
                                <b>Following</b> <a class="float-right">543</a>
                            </li>
                            <li class="list-group-item">
                                <b>Friends</b> <a class="float-right">13,287</a>
                            </li>
                        </ul> -->

                        <!-- <a href="#" class="btn btn-primary btn-block"
                            ><b>Follow</b></a
                        > -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->

                <!-- About Me Box -->
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">About Me</h3>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <strong><i class="fas fa-book mr-1"></i> User Name</strong>

                        <p class="text-muted">
                            {{staffUserName}}
                        </p>

                        <hr />

                        <strong>
                            <i class="fas fa-map-marker-alt mr-1"></i>
                            Conatact Number
                        </strong>

                        <p class="text-muted">{{staffMobileNumber}}</p>

                        <hr />

                        <strong><i class="fas fa-pencil-alt mr-1"></i>
                            E-Mail Address</strong>

                        <p class="text-muted">
                            {{staffEmailId}}
                        </p>

                        <hr />

                        <strong><i class="far fa-file-alt mr-1"></i> Status</strong>

                        <p class="text-muted"
                            [ngClass]="{'text-success': staffStatus === 1, 'text-danger': staffStatus === 0}">
                            {{ staffStatus === 1 ? 'active' : 'deactive' }}
                        </p>

                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Change Password</h3>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="changePasswordForm" (ngSubmit)="changeStaffPassword()" method="post">
                            <div class="form-group">
                                <label for="oldPassword">Old Password</label>
                                <input type="password" class="form-control form-control-sm"
                                    formControlName="oldPassword" id="oldPassword" placeholder="Enter old password">
                            </div>
                            <div class="form-group">
                                <label for="newPassword">New Password</label>
                                <input type="password" class="form-control form-control-sm"
                                    formControlName="newPassword" id="newPassword" placeholder="Enter new password">
                            </div>
                            <div class="form-group">
                                <label for="confirmNewPassword">Confirm New Password</label>
                                <input type="password" class="form-control form-control-sm"
                                    formControlName="confirmPassword" id="confirmNewPassword"
                                    placeholder="Confirm new password">
                            </div>
                            <button type="submit" class="btn btn-primary btn-sm">Change Password</button>
                        </form>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>