import {IsAuthService} from '@/service/is-auth.service';
import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit, Renderer2} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {PageEvent} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@services/api.service';
import {ToastrService} from 'ngx-toastr';
import {map, startWith} from 'rxjs';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver-es';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
    selector: 'app-apilogs',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        MatPaginatorModule
    ],
    templateUrl: './apilogs.component.html',
    styleUrl: './apilogs.component.scss'
})
export class ApilogsComponent implements OnInit {
    prop: any;
    data: any;
    total: any;

    deviceProp: any;
    deviceData: any;
    devicetotal: any;

    isLoading = true; // Add this line to show the loader at the beginning

    filteredSuccessFail$: any;

    successFailFilterCtrl: FormControl = new FormControl();

    length: any; // Replace with your total data count from API
    page = 1;
    pageSize = 10; // Replace with your default page size
    pageSizeOptions: number[] = [2, 5, 10, 25, 50, 100]; // Or your page size options
    pageEvent: PageEvent;

    constructor(
        private modalService: NgbModal,
        private service: ApiService,
        private toast: ToastrService,
        private router: Router,
        private isAuth: IsAuthService,
        private http: HttpClient,
        private renderer: Renderer2
    ) {
        this.pageEvent = new PageEvent();

        this.filteredSuccessFail$ =
            this.successFailFilterCtrl.valueChanges.pipe(
                startWith(''),
                map((filterString) => this.filter(filterString))
            );
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput
            .split(',')
            .map((str) => +str);
    }

    searchDeviceName: string;

    ngOnInit(): void {
        this.modelListAPI();
        this.searchDeviceName = this.isAuth.getDeviceId();
        this.showLogAPI();
    }

    displayedLogData: any;

    filterFromTable(input: any) {
        //console.log(input);

        if (!input) {
            // If there's no input, reset to the original dataset
            this.displayedLogData = [...this.data];
        } else {
            const res = this.data.filter((device) =>
                Object.values(device).some(
                    (prop) =>
                        prop !== null &&
                        prop !== undefined &&
                        prop
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                )
            );
            this.displayedLogData = res;
        }
    }

    sortData(sortField: string, sortDirection: string) {
        this.displayedLogData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    capitalizeAndReplace(str: string): string {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
    }

    modelListAPI() {
        // try {
        //   this.service.getModelListAPI().subscribe((data1: any) => {
        //     //console.log('Model list', data1);
        //     if (data1['status'] == true) {
        //       //console.log("here");
        //       this.deviceProp = [];
        //       for (var propertyDevice in data1['data'][0]) {
        //         if (data1['data'][0].hasOwnProperty(propertyDevice)) {
        //           //console.log("PROPERTY",property);
        //           if (propertyDevice.toLowerCase() != 'total') {
        //             this.deviceProp.push(propertyDevice);
        //           }
        //           //console.log("prop",this.deviceProp);
        //         }
        //       }
        //       this.deviceData = data1['data'];
        //       this.devicetotal = data1['total_models'];
        //       this.isLoading = false;
        //       //console.log("data",this.data);
        //     } else {
        //       this.deviceData = null;
        //       this.toast.error("Data Not Found");
        //       this.isLoading = false;
        //     }
        //   });
        // } catch (error) {
        //   console.log(error);
        // }
    }

    showLogAPI() {
        const deviceName = this.isAuth.getDeviceId();
        try {
            let sendData = {
                device_name: deviceName,
                page: this.page,
                per_page: this.pageSize
            };

            //console.log("send data",sendData);

            this.service.postAPILogs(sendData).subscribe((data: any) => {
                //console.log("Request Log API Response",data);
                if (data['status'] == true) {
                    //console.log("here");

                    this.prop = [];
                    for (var property in data['data'][0]) {
                        if (data['data'][0].hasOwnProperty(property)) {
                            //console.log("PROPERTY",property);
                            if (
                                property.toLowerCase() != 'total' &&
                                property.toLowerCase() != 'user_id'
                            ) {
                                this.prop.push(property);
                            }
                            //console.log(this.prop);
                        }
                    }

                    this.rearrangeArray();
                    this.isLoading = false;
                    this.data = data['data'];
                    this.displayedLogData = [...this.data];
                    this.length = data['total_entries'];
                    this.total = data['total_entries'];
                } else {
                    this.isLoading = false;
                    this.data = null;
                    this.toast.error('No Data Found');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    filter(filterString: string) {
        return this.showSuccessFailModel
            ? this.showSuccessFailModel.filter(
                  (option: any) =>
                      option['model_name'] &&
                      option['model_name']
                          .toLowerCase()
                          .includes(filterString.toLowerCase())
              )
            : [];
    }

    userName = localStorage.getItem('UserName');

    displaySearchForm = false;

    toggleSearchForm() {
        this.displaySearchForm = !this.displaySearchForm;
    }

    pageIndex: number = 0;

    handlePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        //console.log(event.pageSize); // This will give you the current items per page
        this.loadData(event.pageIndex, event.pageSize);
    }

    loadData(pageIndex: number, pageSize: number) {
        // Call your API here with pageIndex and pageSize as parameters
        this.isLoading = true;
        if (pageIndex == 0) {
            pageIndex = 1;
        } else {
            pageIndex++;
        }

        const deviceName = this.isAuth.getDeviceId();
        try {
            let sendData = {
                device_name: deviceName,
                page: pageIndex,
                per_page: pageSize
            };

            //console.log("send data",sendData);
            //console.log(sendData,sendData['page'],sendData['per_page']);

            this.service.postAPILogs(sendData).subscribe((data: any) => {
                //console.log("Request Log API Response",data);
                if (data['status'] == true) {
                    //console.log("here");

                    this.prop = [];
                    for (var property in data['data'][0]) {
                        if (data['data'][0].hasOwnProperty(property)) {
                            //console.log("PROPERTY",property);
                            if (
                                property.toLowerCase() != 'total' &&
                                property.toLowerCase() != 'user_id'
                            ) {
                                this.prop.push(property);
                            }
                            //console.log(this.prop);
                        }
                    }

                    this.rearrangeArray();
                    this.isLoading = false;
                    this.data = data['data'];
                    this.displayedLogData = [...this.data];
                    this.length = data['total_entries'];
                    this.total = data['total_entries'];
                } else {
                    this.isLoading = false;
                    this.data = null;
                    this.toast.error('No Data Found');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    rearrangeArray() {
        // Use the JavaScript splice and unshift methods to move 'Date' to the beginning of the array

        //const dateIdx2 = this.showLogProp.indexOf('device_name');
        // if (dateIdx2 > -1) {
        //   const deviceNameElement = this.showLogProp.splice(dateIdx2, 1)[0];
        //   this.showLogProp.unshift(deviceNameElement);
        // }

        // const dateIdx1 = this.showLogProp.indexOf('group_name');
        // if (dateIdx1 > -1) {
        //   const groupNameElement = this.showLogProp.splice(dateIdx1, 1)[0];
        //   this.showLogProp.unshift(groupNameElement);
        // }

        const dateIdx = this.prop.indexOf('id');
        if (dateIdx > -1) {
            const userNameElement = this.prop.splice(dateIdx, 1)[0];
            this.prop.unshift(userNameElement);
        }
    }

    //fromDate:any //= new Date().toISOString().split("T")[0];  // New variable
    //toDate:any //= new Date().toISOString().split("T")[0];  // New variable

    searchFormData = new FormGroup({
        ipAddress: new FormControl('', [Validators.required]),
        fromDate: new FormControl('', [Validators.required]),
        toDate: new FormControl('', [Validators.required]),
        deviceId: new FormControl('', [Validators.required])
    });

    get ipAddress(): FormControl {
        return this.searchFormData.get('ipAddress') as FormControl;
    }

    changeColor(event: any) {
        this.renderer.setStyle(event.target, 'background-color', 'green');
        this.renderer.setStyle(event.target, 'border-color', 'green');
    }

    applyDateCheckedFromDate: any;
    applyDateCheckedToDate: any;

    showDownloadButtons = false;

    onSearch() {
        //console.log('From date: ', this.searchFormData.value.fromDate);
        //console.log('To date: ', this.searchFormData.value.toDate);
        //console.log("Input", this.searchFormData.value);

        const deviceName = this.isAuth.getDeviceId();
        this.isLoading = true;

        try {
            this.showDownloadButtons = true;

            const dateNew = (<HTMLInputElement>(
                document.getElementById('applyDate')
            )).checked;
            //console.log(dateNew);

            if (dateNew === true) {
                this.applyDateCheckedFromDate =
                    this.searchFormData.value.fromDate;
                this.applyDateCheckedToDate = this.searchFormData.value.toDate;
            } else {
                this.applyDateCheckedFromDate = '';
                this.applyDateCheckedToDate = '';
            }

            let sendData = {
                device_name: deviceName,
                ip_address: this.searchFormData.value.ipAddress,
                test_type: this.selectedTestType,
                sucess_fail_status: this.selectedSuccessFailType,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                page: this.page,
                per_page: this.pageSize
            };

            //console.log('Search send data', sendData);

            this.service.postSearchAPILogs(sendData).subscribe((data: any) => {
                //console.log("search Response",data);
                if (data['status'] == true) {
                    //console.log("here");

                    this.prop = [];
                    for (var property in data['data'][0]) {
                        if (data['data'][0].hasOwnProperty(property)) {
                            //console.log("PROPERTY",property);
                            if (
                                property.toLowerCase() != 'total' &&
                                property.toLowerCase() != 'user_id'
                            ) {
                                this.prop.push(property);
                            }
                            //console.log(this.prop);
                        }
                    }

                    this.rearrangeArray();
                    this.isLoading = false;
                    this.data = data['data'];
                    this.displayedLogData = [...this.data];
                    this.length = data['total_entries'];
                    this.total = data['total_entries'];
                } else {
                    this.isLoading = false;
                    this.data = null;
                    this.toast.error('No Data Found');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    selectedUserName: any;
    showUserNameModel: any;

    changeUserName(value: any) {
        //console.log(value);
    }

    testType = {
        data: [
            {id: 1, testType: 'Text To Speech'},
            {id: 5, testType: 'Text To Speech(Group)'},
            {id: 2, testType: 'Test API'},
            {id: 3, testType: 'Print Test'},
            {id: 4, testType: 'Test Kiotel'},
            {id: 6, testType: 'Test Api(amount)'},
            {id: 7, testType: 'Test Audio(device)'}
        ]
    };

    selectedTestType: any;
    showTestTypeModel: any = this.testType['data'];

    changeTestType(value: any) {
        //console.log(value);
        this.selectedTestType = value;
    }

    status = {
        data: [
            {id: 1, status: 'success'},
            {id: 2, status: 'fail'}
        ]
    };

    selectedSuccessFailType: any;
    showSuccessFailModel: any = this.status['data'];

    changeSuccessFailType(value: any) {
        //console.log(value);
        this.selectedSuccessFailType = value;
    }

    selectedModelName: any;

    changeModelName(value: any) {
        //console.log(value);
        this.selectedModelName = value;
    }

    isPdfDownloaded = false;
    isXLSDownloaded = false;
    isCSVDownloaded = false;

    onDownloadPDF() {
        // Implement your PDF download functionality here.
        //console.log('Download PDF button clicked!');

        const deviceName = this.isAuth.getDeviceId();

        try {
            let sendData = {
                device_name: deviceName,
                ip_address: this.searchFormData.value.ipAddress,
                test_type: this.selectedTestType,
                sucess_fail_status: this.selectedSuccessFailType,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                download_type: 'pdf'
            };

            //console.log(sendData);

            this.service.postDownloadAPILogs(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'log.pdf'); // replace 'filename.pdf' with your desired filename
                    this.isPdfDownloaded = true; // Set to true when PDF is downloaded
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    onDownloadCSV() {
        // Implement your CSV download functionality here.
        //console.log('Download CSV button clicked!');
        const deviceName = this.isAuth.getDeviceId();
        try {
            let sendData = {
                device_name: deviceName,
                ip_address: this.searchFormData.value.ipAddress,
                test_type: this.selectedTestType,
                sucess_fail_status: this.selectedSuccessFailType,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                download_type: 'csv'
            };

            //console.log(sendData);

            this.service.postDownloadAPILogs(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'log.csv'); // replace 'filename.pdf' with your desired filename
                    this.isCSVDownloaded = true;
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    onDownloadXLSX() {
        // Implement your XLSX download functionality here.
        //console.log('Download XLSX button clicked!');
        const deviceName = this.isAuth.getDeviceId();
        try {
            let sendData = {
                device_name: deviceName,
                ip_address: this.searchFormData.value.ipAddress,
                test_type: this.selectedTestType,
                sucess_fail_status: this.selectedSuccessFailType,
                from_date: this.applyDateCheckedFromDate,
                to_date: this.applyDateCheckedToDate,
                download_type: 'excel'
            };

            //console.log(sendData);

            this.service.postDownloadAPILogs(sendData).subscribe(
                (data: Blob) => {
                    saveAs(data, 'log.xlsx'); // replace 'filename.pdf' with your desired filename
                    this.isXLSDownloaded = true;
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    reloadPage() {
        // Navigate to a dummy route first
        this.router
            .navigateByUrl('/refresh', {skipLocationChange: true})
            .then(() => {
                // Then navigate back to the current route
                this.router.navigateByUrl('/apiLogs');
            });
    }

    logout() {
        this.isAuth.logout();
    }
}
