<ul class="navbar-nav right-menu ml-auto">
    <li class="nav-item">
        <div [formGroup]="formGroup">
            <mat-form-field class="select-outline-white w-auto">
                <mat-label>Business Dashboard</mat-label>
                <mat-select formControlName="activationStatus">
                    <mat-option value="Option 1">Option 1</mat-option>
                    <mat-option value="Option 2">Option 2</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </li>
    <li class="nav-item">
        <button class="btn btn-gray">
            <span>
                <img src="assets/img/help-dark.svg" alt="" />
                Help
            </span>
        </button>
    </li>
    <li class="nav-item">
        <app-user></app-user>
    </li>
</ul>